import SocketService from "@/services/SocketService";
import {EntityMessage, NetworkMessage} from "@/services/NetworkMessage";
import {MessageReader} from "@/types/MessageReader";
import {Entity} from "@/types/Entity";
import {
    NetworkBoolean,
    NetworkFloat32,
    NetworkInt32,
    NetworkPropertyType,
    NetworkString
} from "@/services/NetworkProperty";

class EntityService extends EventTarget {
    private socket: SocketService;
    private entities: Map<number, Entity>;

    constructor(socket: SocketService) {
        super();
        this.socket = socket;
        this.entities = new Map();

        this.socket.addEventListener("binaryReceived", (message) => {
            const networkMessage = message as CustomEvent<NetworkMessage>;

            if (!EntityMessage.checkType(networkMessage.detail)) {
                return;
            }

            this.handleEntityMessage(networkMessage.detail);
        });
    }

    private handleEntityMessage(message: EntityMessage) {
        const reader = new MessageReader(message.data);

        const numEntities = reader.readInt32();
        for (let i = 0; i < numEntities; i++) {
            const id = reader.readInt32();
            const entity = this.ensureEntity(id);

            const numProperties = reader.readInt32();
            for (let j = 0; j < numProperties; j++) {
                const type = reader.readInt32() as NetworkPropertyType;
                const name = reader.readString();

                // TODO: Sanity check that type is a valid property type!!

                switch (type) {
                    case NetworkPropertyType.Int32: {
                        const value = reader.readInt32();
                        console.log(`READ INT32 ${name} ${value}`);

                        const property = entity.properties.ensure(NetworkInt32, name);
                        property.value = value;
                    }
                        break;
                    case NetworkPropertyType.Float32: {
                        const value = reader.readFloat32();
                        console.log(`READ FLOAT32 ${name} ${value}`);

                        const property = entity.properties.ensure(NetworkFloat32, name);
                        property.value = value;
                    }
                        break;
                    case NetworkPropertyType.Boolean: {
                        const value = reader.readBoolean();
                        console.log(`READ BOOLEAN ${name} ${value}`);

                        const property = entity.properties.ensure(NetworkBoolean, name);
                        property.value = value;
                    }
                        break;
                    case NetworkPropertyType.String: {
                        const value = reader.readString()
                        console.log(`READ STRING ${name} ${value}`);

                        const property = entity.properties.ensure(NetworkString, name);
                        property.value = value;
                    }
                        break;
                }
            }


            // Notify
            entity.invokeEntityUpdated();
            //this.invokeEntityUpdated(entity);
        }
    }

    private ensureEntity(id: number): Entity {
        let entity = this.entities.get(id);
        if (entity === undefined) {
            entity = this.createEntity(id);
        }

        return entity;
    }

    private createEntity(id: number): Entity {
        const entity = new Entity(id);

        console.log(`Create Entity ${id}`);

        this.entities.set(id, entity);

        this.invokeEntityCreated(entity);

        return entity;
    }

    private invokeEntityCreated(entity: Entity) {
        const evt = new CustomEvent<Entity>('created', {detail: entity});
        this.dispatchEvent(evt);
    }

    private invokeEntityUpdated(entity: Entity) {
        const evt = new CustomEvent<Entity>('updated', {detail: entity});
        this.dispatchEvent(evt);
    }
}

export default EntityService;
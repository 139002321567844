import {MessageWriter} from "@/types/MessageWriter";
import {MessageReader} from "@/types/MessageReader";
import {NetworkMessage, NetworkMessageFactory} from "@/services/NetworkMessage";

class SocketService extends EventTarget {
    private writer: MessageWriter;
    private socket: WebSocket | null = null;
    private factory: NetworkMessageFactory;

    // TODO: SocketService must really prevent multi-connect calls.

    constructor() {
        super();

        this.writer = new MessageWriter();
        this.factory = new NetworkMessageFactory();
    }

    connect(url: string) {
        this.socket = new WebSocket(url);
        this.socket.binaryType = "arraybuffer";

        this.socket.onopen = () => {
            const evt = new CustomEvent('open');
            this.dispatchEvent(evt);
        };

        this.socket.onmessage = (message) => {
            if (typeof (message.data) === 'string') { // String
                // TODO: Do we just ignore text messages? We should NEVER receive or handle them yet
                return;
            } else { // Binary
                const networkMessage = this.factory.parseMessage(message.data);

                const evt = new CustomEvent<NetworkMessage>('binaryReceived', {detail: networkMessage});
                this.dispatchEvent(evt);
            }
        };

        this.socket.onclose = () => {
            const evt = new CustomEvent('closed')
            this.dispatchEvent(evt);
        };

        this.socket.onerror = (error) => {
            console.log("socket error", error);
        };
    }

    send(message: string | ArrayBuffer) {
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
            this.socket.send(message);
        } else {
            // TODO: Error
        }
    }

    close() {
        if (this.socket) {
            this.socket.close();
        }
    }
}

export default SocketService;
export enum NetworkPropertyType {
    Boolean = 0,
    Int32 = 1,
    String = 2,
    Float32 = 3,
}

class BaseNetworkProperty {
    type: NetworkPropertyType;
    name: string;

    constructor(type: NetworkPropertyType, name: string) {
        this.type = type;
        this.name = name;
    }
}

export class NetworkBoolean extends BaseNetworkProperty {
    _value: boolean = false;

    get value(): boolean {
        return this._value;
    }

    set value(value: boolean) {
        this._value = value;
    }

    constructor(name: string) {
        super(NetworkPropertyType.Boolean, name);
    }
}

export class NetworkInt32 extends BaseNetworkProperty {
    _value: number = 0;

    get value(): number {
        return this._value;
    }

    set value(value: number) {
        this._value = value;
    }

    constructor(name: string) {
        super(NetworkPropertyType.Int32, name);
    }
}


export class NetworkFloat32 extends BaseNetworkProperty {
    _value: number = 0;

    get value(): number {
        return this._value;
    }

    set value(value: number) {
        this._value = value;
    }

    constructor(name: string) {
        super(NetworkPropertyType.Int32, name);
    }
}

export class NetworkString extends BaseNetworkProperty {
    _value: string = "";

    get value(): string {
        return this._value;
    }

    set value(value: string) {
        this._value = value;
    }

    constructor(name: string) {
        super(NetworkPropertyType.Int32, name);
    }
}

export type NetworkProperty = NetworkInt32 | NetworkString | NetworkBoolean | NetworkFloat32;

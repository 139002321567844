import {MessageReader} from "@/types/MessageReader";

export class EntityMessage {
    data: ArrayBuffer; // Unstructured entity data (to be manually parsed)
    
    constructor(data: ArrayBuffer) {
        this.data = data;
    }

    static checkType(message: NetworkMessage): message is EntityMessage {
        return message instanceof EntityMessage;
    }
}

export class DestroyMessage {
    entities: string[];

    constructor(entities: string[]) {
        this.entities = entities;
    }

    static checkType(message: NetworkMessage): message is DestroyMessage {
        return message instanceof DestroyMessage;
    }
}

export type NetworkMessage = EntityMessage | DestroyMessage;

/**
 * Defines the transmitted types over the network which represent a particular message.
 */
enum NetworkMessageType {
    Entity = 1,
    Destroy = 2
}

/**
 * Provides behaviour for instantiating network messages from a raw byte payload.
 */
export class NetworkMessageFactory {
    parseMessage(buffer: ArrayBuffer): NetworkMessage {
        const reader = new MessageReader(buffer);

        const type = reader.readInt32() as NetworkMessageType;
        if (!(type in NetworkMessageType)) {
            throw new Error(`Undefined message type ${type}`);
        }

        switch (type) {
            case NetworkMessageType.Entity:
                return this.parseEntityMessage(reader);
            case NetworkMessageType.Destroy:
                return this.parseDestroyMessage(reader);
        }
    }

    private parseEntityMessage(reader: MessageReader): EntityMessage {
        // Read the remaining data as an unstructured payload
        const buffer = reader.readBuffer(reader.length - reader.currentPosition);

        return new EntityMessage(buffer);
    }

    private parseDestroyMessage(reader: MessageReader): DestroyMessage {
        throw new Error("Not implemented");
    }
}

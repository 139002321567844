import {NetworkProperties} from "@/services/NetworkProperties";
import {NetworkInt32} from "@/services/NetworkProperty";

export class Entity extends EventTarget{
    _id: number;
    _properties: NetworkProperties = new NetworkProperties();
    
    get properties() : NetworkProperties {
        return this._properties;
    }
    
    get id(): number {
        return this._id;
    }

    constructor(id: number) {
        super();
        this._id = id;
        
        const t = this._properties.ensure(NetworkInt32, "hello");
    }
    
    invokeEntityUpdated() {
        const evt = new CustomEvent<Entity>('updated', {detail: this});
        this.dispatchEvent(evt);
    }
}


<template>
    <canvas class="babylon-canvas" ref="bjsCanvas" width="500" height="500"></canvas>
</template>

<script lang="ts"> import {ref, onMounted, inject} from 'vue';
import {createScene} from '../scenes/MyFirstScene';
//import SocketService from "@/services/SocketService";
import EntityService from "@/services/EntityService";
//import SocketService from "@/services/SocketService";

export default {
  name: 'BabylonComponent', 
  components: {
    
    
  },
  setup() {
    const bjsCanvas = ref<HTMLCanvasElement | null>(null);

    const entityService = inject<EntityService>('entityService');
    
    //const network = new SocketService();

    onMounted(() => {
      if (bjsCanvas.value) {
        createScene(bjsCanvas.value, entityService!);
      }
    });
    return {bjsCanvas};
  },
}; </script>

<style scoped>

.babylon-canvas {
  width: 75vw;
  height: 75vh;
  border: 2px solid #ff7f50; /* Coral border */ 
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Optional: add a shadow for better visibility */
}

</style>
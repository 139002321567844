<template>
  <div>
    <div v-if="visible" class="connection-form">
      <input v-model="address">
      <button @click="connect">Connect</button>
    </div>
  </div>
</template>
<script lang="ts"> import {inject, onMounted, ref} from 'vue';

import SocketService from "../services/SocketService";

export default {
  name: 'NetworkComponent',
  setup() {
    const visible = ref(true);
    const address = ref('wss://localhost:55001/ws/connect/');
    
    const socketService = inject<SocketService>('socketService');

    if (!socketService) {
      throw new Error("SocketService missing");
    }

    onMounted(() => {
      socketService.addEventListener('open', () => {
        visible.value = true;
      });
      
      socketService.addEventListener('closed', () => {
        visible.value = false;
      });
    });

    //const entityStore = useEntityStore();

    const message = ref<string | null>(null);
    // const wsService = new WebSocketService('wss://echo.websocket.org/');
    //const wsService = new WebSocketService('wss://localhost:55001/ws/connect/');
    const connect = () => {
      
      // TODO: Null check address
      
      socketService.connect(address.value!);
      socketService.addEventListener('textReceived', (event: Event) => {
        console.log('TEXT', event);
      });

      socketService.addEventListener('binaryReceived', (event: Event) => {
        console.log('BINARY', event);
      });
    };
    const disconnect = () => {
      socketService.close();
    };
    const sendMessage = () => {

      const buf = new ArrayBuffer(4);
      const view = new DataView(buf);
      view.setInt32(0, 27, false);
      socketService.send(buf);

      socketService.send("HELLO");
      //wsService.sendMessage('Hello from Vue!');
    };
    return {connect, disconnect, sendMessage, message, visible, address};
  },
}; </script>

<style scoped>
.connection-form {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 20px;
}

.connection-form input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 250px;
}

.connection-form button{
  padding: 6px 12px;
  border: none;
  background-color: #ff7f50;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.connection-form button:hover {
  background-color: #ff6347;
}

.connection-form button:active {
  background-color: #ee5a46;
}

</style>
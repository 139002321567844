<template>
  <div id="app">
    <div class="container">
      <BabylonComponent/>
      <NetworkComponent/>
      <VersionComponent/>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import NetworkComponent from './components/Network.vue';
import BabylonComponent from './components/Babylon.vue';
import VersionComponent from "@/components/Version.vue";

@Options({
  components: {
    VersionComponent,
    BabylonComponent,
    NetworkComponent
  },
})
export default class App extends Vue {}
</script>

<style>
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #2e2e2e;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center horizontally */ 
  justify-content: center; /* Center vertically */ 
  min-height: 100vh; /* Ensure full height of the viewport */
}
</style>

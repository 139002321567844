import { createApp } from 'vue'
import App from './App.vue'
import SocketService from "@/services/SocketService";
import EntityService from "@/services/EntityService";

const socketService = new SocketService();
const entityService = new EntityService(socketService);

const app = createApp(App);
app.provide('socketService', socketService);
app.provide('entityService', entityService);
app.mount('#app');

//createApp(App).mount('#app')

<template>
  <div class="version">
    v{{ version }}
  </div>
</template>

<script lang="ts">

import {version} from '@/../package.json';

export default {
  name: 'VersionComponent',
  components: {},
  setup() {
    return {version};
  },
}; </script>

<style scoped> .version {
  position: fixed;
  bottom: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
}
</style>
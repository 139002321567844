import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "app" }
const _hoisted_2 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BabylonComponent = _resolveComponent("BabylonComponent")!
  const _component_NetworkComponent = _resolveComponent("NetworkComponent")!
  const _component_VersionComponent = _resolveComponent("VersionComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BabylonComponent),
      _createVNode(_component_NetworkComponent),
      _createVNode(_component_VersionComponent)
    ])
  ]))
}
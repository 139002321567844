import {NetworkProperty} from "@/services/NetworkProperty";

export class NetworkProperties {
    private properties: Map<string, NetworkProperty> = new Map();

    constructor() {

    }

    get(index: string): NetworkProperty | undefined {
        return this.properties.get(index);
    }

    ensure<T extends NetworkProperty>(ctor: new(name: string) => T, name: string): T {
        const existing = this.properties.get(name);
        
        // Does not exist
        if(!existing) {
            return this.create(ctor, name);
        }
        
        // Exists and matches type
        if (existing && existing instanceof ctor) {
            return existing as T;
        }
        
        // Exists but does not match type (remove existing, create new)
        this.properties.delete(name);
        return this.create(ctor, name);
    }

    private create<T extends NetworkProperty>(ctor: new(name: string) => T, name: string): T {
        console.log(`CREATE PROPERTY ${name} ${ctor.name}`);
        
        const instance = new ctor(name);
        this.properties.set(name, instance);
        return instance;
    }
}
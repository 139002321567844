import {Engine, Scene, ArcRotateCamera, Vector3, MeshBuilder, HemisphericLight, Mesh} from '@babylonjs/core';
//import {useEntityStore} from '@/stores/entityStore';
import {Entity} from "@/types/Entity";
import EntityService from "@/services/EntityService";
import {NetworkFloat32, NetworkInt32} from "@/services/NetworkProperty";

export function createScene(canvas: HTMLCanvasElement, entityService: EntityService) {
    //const entityStore = useEntityStore();

    const engine = new Engine(canvas, true);
    const scene = new Scene(engine);
    const camera = new ArcRotateCamera('camera1', 0, 0, 10, Vector3.Zero(), scene);
    camera.attachControl(canvas, true);

    const light = new HemisphericLight('light1', new Vector3(1, 1, 0), scene);

    // const createEntity = (entity: Entity) => {
    //     const cube = MeshBuilder.CreateBox('cube', { size: 1 }, scene);
    // };

    entityService.addEventListener('created', (evt : Event) => {
        const entityEvent = evt as CustomEvent<Entity>;
        const watcher = new EntityWatcher(entityEvent.detail, scene);
        
        // TODO: Keep track of watchers
        
        //const cube = MeshBuilder.CreateBox('cube', {size: 1}, scene);
    });
    
    entityService.addEventListener('updated', () => {
        
    })

    engine.runRenderLoop(() => {
        scene.render();
    });
    window.addEventListener('resize', () => {
        engine.resize();
    });
    return scene;
}

class EntityWatcher {
    private entity: Entity;
    private mesh: Mesh;
    private scene: Scene;
    
    constructor(entity: Entity, scene: Scene) {
        this.entity = entity;
        this.scene = scene;
        this.mesh = MeshBuilder.CreateBox('cube', {size: 1}, scene);
        
        entity.addEventListener('updated', () => {
            const x = this.entity.properties.get("x") as NetworkFloat32;
            const y = this.entity.properties.get("y") as NetworkFloat32;
            
            this.mesh.position.set(x.value, 0, y.value);
        })
    }
}

// export class WorldEntityService {
//     private scene : Scene;
//     private entities : Map<string, Entity>;
//     
//     constructor(scene : Scene) {
//         this.scene = scene;
//         this.entities = new Map<string, Entity>();
//     }
// 
//     public addEntity(entity: Entity) {
//         
//     }
//     
//     public removeEntity(entity: Entity) {
//         
//     }
// }
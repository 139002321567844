export class MessageReader {
    private position: number;
    private buffer: ArrayBuffer;
    private view: DataView;

    get currentPosition() {
        return this.position;
    }

    get length() {
        return this.buffer.byteLength;
    }

    constructor(buffer: ArrayBuffer) {
        this.buffer = buffer;
        this.view = new DataView(this.buffer);
        this.position = 0;
    }

    readBoolean(): boolean {
        this.checkCapacity(1);
        const rawValue = this.view.getUint8(this.position);
        this.position++;

        return rawValue !== 0;
    }

    readInt32(): number {
        this.checkCapacity(4);
        const value = this.view.getInt32(this.position, false);
        this.position += 4;
        return value;
    }

    readFloat32(): number {
        this.checkCapacity(4);
        const value = this.view.getFloat32(this.position, false);
        this.position += 4;
        return value;
    }
    
    readString(): string {
        const length = this.readInt32();

        // TODO: Validate this length
        // TODO: New buffer = garbage, reuse

        const stringData = new Uint8Array(this.buffer, this.position, length);
        const decoder = new TextDecoder();
        const value = decoder.decode(stringData);
        this.position += length;

        return value;
    }

    readBuffer(length: number): ArrayBuffer {
        // TODO: Bounds check

        const slice = this.buffer.slice(this.position, this.position + length);
        this.position += length;

        return slice;
    }

    private checkCapacity(capacity: number) {
        if (this.position + capacity > this.buffer.byteLength) {
            throw new Error("Out of bounds");
        }
    }
}

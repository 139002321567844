export class MessageWriter {
    private position: number;
    private encoder: TextEncoder;
    private buffer: ArrayBuffer;
    private view: DataView;

    constructor() {
        this.buffer = new ArrayBuffer(1024);
        this.view = new DataView(this.buffer);
        this.position = 0;
        this.encoder = new TextEncoder();
    }

    writeInt32(value: number) {
        this.ensureCapacity(4);
        this.view.setInt32(this.position, value);
        this.position += 4;
    }
    
    writeString(value: string) {
        const payload = this.encoder.encode(value);
        this.ensureCapacity(4 + payload.length);
        
        // TODO: New buffer = garbage, reuse
        
        new Uint8Array(this.buffer, this.position, length).set(payload);
        
        this.position += 4 + payload.length;
    }

    getBuffer(): ArrayBuffer {
        return this.buffer.slice(0, this.position);
    }

    private ensureCapacity(capacity: number) {
        const neededCapacity = capacity + this.position;

        // Resize if needed
        if (neededCapacity > this.buffer.byteLength) {
            const newSize = this.calculateSize(neededCapacity);
            this.resizeBuffer(newSize);
        }
    }

    private resizeBuffer(capacity: number) {
        const newBuffer = new ArrayBuffer(capacity);
        new Uint8Array(newBuffer).set(new Uint8Array(this.buffer));
        this.buffer = newBuffer;
        this.view = new DataView(newBuffer);
    }

    private calculateSize(capacity: number): number {
        let num = this.buffer.byteLength;
        while (num < capacity) {
            num *= 2;
        }

        return num;
    }
}

